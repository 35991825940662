video {
  width: 100% !important;
  height: auto !important;
}

.anr-simple__container-fluid {
  width: 100%;
  max-width: 1366px;
  min-width: 768px;
  margin: 0 auto 24px auto;
  position: relative;
  overflow: hidden;
  color: #ffffff;
}

.anr-simple__eyebrow {
  left: 8em;
  position: absolute;
  top: 26em;
  z-index: 3;
  letter-spacing: 0.2em;
}

.anr-simple__header {
  position: absolute;
  z-index: 3;
  top: 0.63em;
  left: 1em;
  font-size: 70px;
}

.anr-simple__header-headline {
  font-size: 50px;
  line-height: 50px;
}

#classics__carousel-1 {
  text-align: left;
}

#classics__carousel-1 .sumptuous-knockout__topmainText {
  text-align: center;
  left: 0.32%;
}

#classics__carousel-2 .sumptuous-knockout__topmainText {
  left: 0.1%;
}

#classics__carousel-img-1b {
  position: absolute;
  z-index: 0;
  margin-left: 2.5%;
  margin-top: -3.75%;
  display: inline-block;
  width: 65.813%;
}

#classics__carousel-img-2b {
  position: absolute;
  z-index: 0;
  margin-left: 5.5%;
  margin-top: -3.5%;
  display: inline-block;
  width: 95.828%;
}

#classics__carousel-img-3b {
  position: absolute;
  z-index: 0;
  margin-left: 5.5%;
  margin-top: -6.95%;
  display: inline-block;
  width: 93.119%;
}

#classics__carousel-pagination {
  position: relative;
  z-index: 5;
}

.classics__carousel-text.classics__carousel-text3 {
  display: none;
}

#classics__carousel-img-2 {
  margin: 0 -2% 0 0;
  vertical-align: top;
}

#classics__carousel-img-2b {
  margin-left: -29%;
  margin-top: -6%;
  width: 100%;
}

.classics__carousel-img-container {
  display: inline-block;
  margin-top: 0.1%;
  width: 32.7%;
}

.classics__carousel-img-container-right {
  display: inline-block;
  margin-right: 0;
  width: 32.7%;
}

.classics__carousel-img-container #classics__carousel-img-1,
.classics__carousel-img-container-right #classics__carousel-img-2,
.classics__carousel-img-container #classics__carousel-img-3 {
  width: 100%;
}

#classics__carousel-2 .classics__carousel-img-container-right #classics__carousel-img-1 {
  width: 100%;
}

#classics__carousel-img-1-gif {
  position: absolute;
  margin-top: -99%;
  margin-left: 5.15%;
  width: 91.6%;
}

#classics__carousel-img-2-gif {
  position: absolute;
  margin-left: -44.5%;
  margin-top: -99%;
  width: 91.6%;
}

#classics__carousel-img-3-gif {
  position: absolute;
  margin-top: -98.7%;
  margin-left: -45.5%;
  width: 91.6%;
}

.isFF #classics__carousel-img-1-gif {
  position: absolute;
  margin-left: 6.7%;
  width: 88.89%;
  margin-top: -97%;
}

.ie #classics__carousel-img-1-gif {
  position: absolute;
  margin-top: 5.3%;
  margin-left: -94.5%;
  width: 89%;
}

.ie #classics__carousel-img-2-gif {
  position: absolute;
  margin-left: -94.5%;
  width: 89%;
  margin-top: 5%;
}

.ie #classics__carousel-img-3-gif {
  position: absolute;
  margin-left: -94.5%;
  width: 89%;
  margin-top: 5%;
}

.isSafari #classics__carousel-img-1-gif {
  position: absolute;
  margin-top: 5%;
  margin-left: -94%;
  width: 89%;
}

.isSafari #classics__carousel-img-2-gif {
  position: absolute;
  margin-top: 5%;
  margin-left: -93.2%;
  width: 89%;
}

.isSafari #classics__carousel-img-3-gif {
  position: absolute;
  margin-top: 5%;
  margin-left: -93.5%;
  width: 89%;
}

.ie .classics__carousel-img-container.classics__carousel-img-container-3 {
  margin-left: 36%;
}

.ie #classics__carousel-img-3b {
  margin-left: 25.5%;
}

.classics__carousel-bodyspace {
  margin-bottom: 8px;
}
/* phase 4 */
.classics__carousel-text {
  padding: 1.35% 1.05% 1.05% 2%;
  width: 33.5% !important;
  margin-left: 35.5%;
  position: relative;
  z-index: 4;
}
/* phase 4 */
#classics__carousel-sub-header1 {
  font-family: 'OptimaDisplayBook';
  text-align: left;
  font-size: 48px;
  text-transform: capitalize;
  letter-spacing: 0px;
  font-weight: normal;
  line-height: 52px;
  margin-left: -4%;
}

.classics__carousel-2,
.classics__carousel-3 {
  display: none;
}

.classics__carousel-text2 {
  /* margin-left: 20%; */
  margin-right: 0;
  opacity: 0;
}

.classics__carousel-img-container-right {
  margin-left: -37.4%;
  margin-right: 0;
  position: absolute;
  z-index: 1;
}

.classics__carousel-img-container.classics__carousel-img-container-3 {
  margin-left: -28.5%;
  margin-right: 0;
  position: absolute;
  z-index: 1;
}

#classics__carousel-img-3b {
  margin-left: -8%;
  margin-right: 0;
}

@media only screen and (min-width: 768px) {
  .anr-simple__header {
    font-size: 90px;
  }
  .anr-simple__header-headline {
    font-size: 90px;
    line-height: 90px;
  }
}

@media only screen and (max-width: 768px) {
  .isTouch #classics__carousel-img-1-gif {
    margin-top: -97%;
    margin-left: 15.8%;
    width: 89%;
  }
  .isTouch #classics__carousel-img-2-gif {
    margin-top: 12%;
    margin-left: -93%;
    width: 89%;
  }
  .isTouch #classics__carousel-img-3-gif {
    margin-top: -97%;
    margin-left: -34%;
    width: 89%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .isTouch #classics__carousel-img-1-gif {
    margin-top: 12%;
    margin-left: -93%;
    width: 89%;
  }
  .isTouch #classics__carousel-img-2-gif {
    margin-top: 12%;
    margin-left: -93%;
    width: 89%;
  }
  .isTouch #classics__carousel-img-3-gif {
    margin-top: 12%;
    margin-left: -93%;
    width: 89%;
  }
}

@media only screen and (min-width: 1280px) {
  .anr-simple__header {
    font-size: 125px;
  }
  .anr-simple__header-headline {
    font-size: 125px;
    line-height: 100px;
  }
}

.anr-simple__expanded-content {
  display: block;
  height: 100%;
  position: relative;
  width: 100%;
}

@media only screen and (max-width: 1080px) {
  .classics__carousel-text {
    width: 36%;
  }
}

@media only screen and (max-width: 900px) {
  .isFF #classics__carousel-img-1-gif {
    margin-top: -97% !important;
    margin-left: 15%;
  }
  .isFF #classics__carousel-img-3-gif {
    margin-top: -97% !important;
    margin-left: -34.5%;
  }
}

.anr-simple__bg-full {
  z-index: 0;
  position: absolute;
  top: 0;
}
/* ----------- section layouts ------------- */

.anr-simple__section {
  position: relative;
  width: 100%;
  z-index: 3;
}

.anr-simple__section:before,
.anr-simple__section:after {
  content: ' ';
  display: table;
}

.anr-simple__section:after {
  clear: both;
}

.anr-simple__section--section1 {
  height: 36%;
}

.anr-simple__section--section2 {
  height: 49%;
}

.anr-simple__section--section3 {
  height: 15%;
  text-align: center;
}

.anr-simple__section--section1--left {
  top: 15%;
  left: 5.2em;
  position: absolute;
  letter-spacing: 0.2em !important;
}

@media only screen and (min-width: 768px) {
  .anr-simple__section--section1--left {
    top: 15%;
    left: 5.2em;
    letter-spacing: 0.2em;
  }
}

.anr-simple__section--section1--right {
  top: 15%;
  left: 72%;
  position: absolute;
}

@media only screen and (min-width: 768px) {
  .anr-simple__section--section1--right {
    top: 15%;
    left: 76%;
  }
}

.anr-simple__section--section2--left {
  padding: 6% 0 0 1em;
  width: 21%;
  float: left;
}

.anr-simple__section--section2--right {
  padding-top: 8%;
  width: 72%;
  float: left;
}
/* --------- section 1 styles -------------- */

.anr-simple__display {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 1.8em;
  padding-left: 2.1em;
}

.anr-simple__display--large {
  font-size: 82px;
  margin-bottom: -10px;
}
/* --------- section 2 styles -------------- */

.anr-simple__header-subhead {
  font-size: 110px;
  margin-top: -50px;
  text-align: center;
  position: absolute;
  width: 100%;
}

.anr-simple__section--section2--img {
  width: 100%;
  max-width: 95%;
  max-height: 95%;
}

.anr-simple__sec2-intro.text--short {
  width: 98%;
  line-height: 1.5em;
  letter-spacing: 0;
  font-size: 16px;
  text-align: center;
  text-transform: none;
  padding-right: 150px;
}

@media only screen and (min-width: 768px) {
  .anr-simple__sec2-intro {
    width: 85%;
    line-height: 1.5em;
    margin-bottom: 15px;
    padding-right: 150px;
  }
}

@media only screen and (min-width: 1280px) {
  .anr-simple__sec2-intro {
    width: 85%;
  }
}

.anr-simple__sec2plus {
  cursor: pointer;
}

.anr-simple__sec2plus-headline {
  display: inline-block;
  margin-top: 0;
  font-family: 'OptimaDisplayLight';
  font-size: 32px;
}

@media only screen and (min-width: 768px) {
  .anr-simple__sec2plus-headline {
    margin-top: 0.4em;
    font-size: 60px;
  }
  .anr-simple__sec2plus {
    margin-left: 120px;
  }
}

@media only screen and (min-width: 1280px) {
  .anr-simple__sec2plus-headline {
    font-size: 60px;
    margin-top: 0.4em;
  }
}

.anr-simple__sec2hovertext {
  display: none;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 1.5em;
  padding-left: 2.1em;
}
/* --------- section 3 aka footer ----------- */

.anr-simple__cta-wrap {
  text-align: center;
  position: absolute;
  bottom: 100%;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .anr-simple__cta-wrap {
    bottom: 83%;
  }
}

.anr-simple__cta-button {
  background: #ffffff;
  padding: 0 20px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;
  color: #0c0d1f;
  line-height: 2em;
  display: inline-block;
  letter-spacing: 1px;
  line-height: 2.5em;
  padding: 0 2em;
}

.anr-simple__cta-reveal {
  display: none;
  width: 100%;
  padding: 0 10%;
  font-size: 10px;
  line-height: 1.5em;
  position: absolute;
  bottom: 60%;
  text-transform: none;
  font-size: 16px;
  line-height: 1.5em;
  bottom: 29%;
  letter-spacing: 0;
}

@media only screen and (min-width: 768px) {
  .anr-simple__cta-reveal {
    font-size: 16px;
    line-height: 1.5em;
    bottom: 29%;
    letter-spacing: 0;
  }
}

@media only screen and (min-width: 1280px) {
  .anr-simple__cta-reveal {
    padding: 0 20%;
  }
}

.anr-simple__footer {
  position: absolute;
  bottom: 0;
  padding: 10px 0 30px 0;
  width: 100%;
  font-size: 0.9em;
  background: white url('/media/boutiques/anr-simple-drupal/anr_simple_bg-footer.jpg') 0 0 no-repeat;
}
/* ---------- expander button -------------- */

.anr-simple__button-wrap {
  position: relative;
  z-index: 10;
  margin-top: 0px;
  height: 47px;
  width: 100%;
  text-align: center;
  display: none;
}

.anr-simple__button {
  width: 33px;
  height: 33px;
  background: white url('/media/boutiques/anr-simple-drupal/anr_simple_cross.png') 0 -30px no-repeat;
  background-size: 100%;
  text-indent: -99999px;
  color: black;
  border: 1px solid black;
  margin: 0 auto;
  cursor: pointer;
  font-size: 37px;
  font-weight: 200;
  font-family: 'OptimaDisplayLight';
  line-height: 34px;
  display: none;
}

.anr-simple__button.closed {
  line-height: 34px;
  background-position: 0 0;
}

.sumptuous-knockout-anr {
  margin: 72px auto 47px auto;
}

@media only screen and (max-width: 1473px) {
  .classics__carousel-text {
    width: 30%;
  }
}

@media only screen and (max-width: 1250px) {
  .classics__carousel-text {
    width: 40%;
  }
}

@media only screen and (max-width: 900px) {
  #classics__carousel-img-1-gif {
    position: absolute;
    margin-top: -96.1%;
    margin-left: 16%;
    width: 87.9%;
  }
  #classics__carousel-img-2-gif {
    position: absolute;
    margin-left: -93%;
    width: 88%;
    margin-top: 11.5%;
  }
  #classics__carousel-img-3-gif {
    position: absolute;
    margin-top: -96.1%;
    margin-left: -34%;
    width: 87.9%;
  }
}
